<template>
  <div class="terminal-nav">
    <div class="terminal-logo">
      <div class="logo terminal-prompt"><a class="no-style" href="/">Backgammon AI</a></div>
    </div>
    <nav class="terminal-menu">
      <ul>
        <li>
          <router-link class="menu-item" to="/leaderboard">Leaderboard</router-link>
        </li>
        <li>
          <router-link class="menu-item" to="/how-to-play">How to play</router-link>
        </li>
        <li>
          <router-link class="menu-item" to="/about">About the project</router-link>
        </li>
        <li><a class="menu-item" href="https://www.linkedin.com/in/jahlstrand/" target="_blank">LinkedIn</a></li>
      </ul>
    </nav>
  </div>
  <hr>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'WebsiteBase',
  components: {}
}
</script>
