<template>
  <h1>How to play</h1>
  <p>
    Backgammon is a two player game where you try to move your own checkers from the opponents home to your own.
    The white player moves checkers counter clockwise while the black player moves clockwise. The board is divided
    into four quadrants,
  </p>
  <p>
    <b>Moving.</b>
    You start each round by throwing two dices, these show the number of steps you can take with a single checker.
    You may only move one checker and use only one dice at a time. If the player roll two of the same number it
    counts as four dices instead of two. You can only land on points where there are either
    zero checkers, any number of your own checkers or a single checker from the opponent. If there are two or more
    opponent checkers on a point it's blocked. If you land on a point with only one opponent it is jailed and the
    opponent must move out with this checker on it's turn before doing any other moves.
  </p>
  <p>
    <b>Bearing Off.</b>
    When, and only when, a player has all it's checkers within the home quadrant he/she is allowed to start bearing
    checkers off. You may only bear off a checker that would exit the board by the exact number of steps required.
    For example a rolled six would allow the checker at point five to move six steps and bear off.
  </p>
  <p>
    <b>Doubling.</b>
    In this simple implementation there is no doubling and all games won is worth only one point.
  </p>
  <p>
    <b>Gammons and Backgammons.</b> And neither is gammon or backgammon implemented.
  </p>
  <p>
    Detailed rules can be found <a href="https://www.bkgm.com/rules.html">here</a>, or read up on the history of
    Backgammon on <a href="https://en.wikipedia.org/wiki/Backgammon">Wikipedia</a>.
  </p>
</template>

<script>
export default {
  name: "HowToPlay",
}
</script>

<style scoped>

</style>
