<template>
  <h1>Leaderboard</h1>
  <div v-if="items.length">
    <p>These are the heroes that beat the AI</p>
    <table>
      <thead>
      <tr>
        <th>Rank</th>
        <th>User</th>
        <th>Time</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in items" v-bind:key="item">
        <td>{{ i + 1 }}</td>
        <td>{{ item.user }}</td>
        <td>{{ item.duration.format("d [days] HH[h] mm[m] ss[s]", 0) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <p v-else>Currently no player has been able to beat the AI...</p>
</template>

<script>
import BoardService from "@/services/board-service";

const moment = require("moment");
require("moment-duration-format");

export default {
  name: "LeaderboardPage",
  data() {
    return {
      items: [],
    }
  },
  async created() {
    try {
      let response = await BoardService.leaderboard();
      this.items = response.items;
      for (const itemsKey in this.items) {
        this.items[itemsKey].duration = moment.duration(this.items[itemsKey].elapsed)
      }
    } catch (err) {
      console.error(err);
    }
  }
}
</script>

<style scoped>

</style>
