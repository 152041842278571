import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue';
import axios from 'axios';
import AboutPage from "@/components/AboutPage";
import BackgammonBoard from "@/components/BackgammonBoard";
import LeaderboardPage from "@/components/LeaderboardPage";
import HowToPlay from "@/components/HowToPlay";

axios.defaults.baseURL = "https://backgammon.ahlstrand.me/api";
axios.defaults.withCredentials = true;

const routes = [
  {path: '/', component: BackgammonBoard},
  {path: '/about', component: AboutPage},
  {path: '/leaderboard', component: LeaderboardPage},
  {path: '/how-to-play', component: HowToPlay}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

createApp(App)
    .use(router)
    .mount('#app')
