<template>
  <WebsiteBase/>
</template>

<script>
import WebsiteBase from './components/Base.vue'

export default {
  name: 'App',
  components: {
    WebsiteBase
  }
}
</script>

<style>

.btn-sm {
  padding: 0.55em 1.2em;
}

button:disabled {
  cursor: default;
  background-color: #959595;
  color: black;
  border: 1px solid black;
}

button:disabled:hover {
  cursor: default;
  background-color: #959595;
  color: black;
  border: 1px solid black;
}

pre {
  color: #373838;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

#app {

}
</style>
