import axios from 'axios';

class BoardService {
    static getBoard(username) {
        let u = username ? `/?username=${username}` : '/';
        return axios.get(u, {
            validateStatus: false
        }).then((response) => {
            return response
        });
    }

    static resetBoard() {
        return axios.get('/reset').then((response) => {
            return response.data
        });
    }

    static getNewMoves() {
        return axios.get('/move').then((response) => {
            return response.data
        });
    }

    static makeMove(pos, dice) {
        return axios.get(`/move/${pos}/${dice}`).then((response) => {
            return response.data
        });
    }

    static leaderboard() {
        return axios.get('/leaderboard').then((response) => {
            return response.data
        });
    }

    static stats() {
        return axios.get('/stats').then((response) => {
            return response.data
        });
    }
}

export default BoardService;
