<template>
  <LoadingBar v-if="gameStarted === null"/>
  <div>
    <Transition>
      <form v-if="gameStarted === false">
        <fieldset>
          <div class="form-group">
            <label for="username">To start a game enter your preferred username</label>
            <input id="username" v-model="username" maxlength="32" name="username" placeholder="Username" type="text">
          </div>
          <p>Your username will be published on the leaderboards <i>if</i> you manage to beat the AI</p>
          <button :disabled="username === null || username?.length < 1" class="btn btn-primary"
                  type="button" @click.prevent="startGame">
            Let's go!
          </button>
        </fieldset>
      </form>
    </Transition>

    <Transition>
      <div v-if="gameStarted">
        <div class="d-flex justify-content-between">
          <p>Win probability: {{ winProb }}%</p>
          <button class="btn btn-error" type="button" @click.prevent="resetBoard">Reset game</button>
        </div>

        <pre>{{ pretty }}</pre>

        <div v-if="winner === null">
          <p>You are playing as {{ player }}</p>
          <p>Available moves:</p>
          <div class="btn-group">
            <button v-for="(move, index) in moves" v-bind:key="index"
                    :disabled="isMoving" class="btn btn-default btn-ghost btn-sm"
                    type="button"
                    @click.prevent="makeMoves(index)">
              <span v-if="player === 'WHITE'">{{ 24 - move[0] }}, {{ move[1] }}</span>
              <span v-else>{{ move[0] + 1 }}, {{ move[1] }}</span>
            </button>
          </div>
        </div>
        <div v-else>
          <div v-if="winner === player">
            <h1>Congratulations! You beat the AI at Backgammon</h1>
            <p>Go checkout where you placed on the <a href="/leaderboard">leaderboard</a>!</p>
          </div>

          <h1 v-else>Nice try, but the AI is too smart for you</h1>
        </div>
        <ul class="history">
          <li v-for="move in log.slice().reverse()" v-bind:key="move">{{ move[0] }} {{ move[1] }}</li>
        </ul>
      </div>
    </Transition>

  </div>
</template>

<script>
import BoardService from "@/services/board-service";
import LoadingBar from "@/components/LoadingBar";
import {Transition} from "vue";

export default {
  name: "BackgammonBoard",
  components: {LoadingBar, Transition},
  data() {
    return {
      board: [],
      pretty: null,
      moves: [],
      aiMoves: [],
      winProb: 0,
      player: null,
      winner: null,
      gameStarted: null,
      username: null,
      isMoving: false,
      log: [],
    }
  },
  async created() {
    await this.startGame();
  },
  methods: {
    unpackResponse(response) {
      this.board = response.board;
      this.pretty = response.pretty;
      this.moves = response.moves.sort();
      this.player = response.player;
      this.winner = response.winner;
      this.winProb = Math.round(response.win_prob * 100);
      this.log.push(...response.ai_actions.map(x => ['AI moved:', x]));
    },
    async startGame() {
      try {
        let response = await BoardService.getBoard(this.username);
        if (response.status === 404) {
          console.log("Game not started yet");
          this.gameStarted = false;
        } else if (response.status === 200) {
          this.unpackResponse(response.data);
          this.gameStarted = true;
        } else {
          console.error(response.err);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async resetBoard() {
      try {
        let response = await BoardService.resetBoard();
        this.unpackResponse(response);
        this.gameStarted = true;
        this.log = [];
      } catch (err) {
        console.error(err);
      }
    },
    async makeMoves(index) {
      try {
        this.isMoving = true;
        this.log.push(['Player moved:', this.moves[index]]);
        let response = await BoardService.makeMove(...this.moves[index]);
        this.isMoving = false;
        this.unpackResponse(response);
      } catch (err) {
        console.error(err);
      }
    }
  }

}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.history {
  max-height: 400px;
  overflow: auto;
}
</style>
